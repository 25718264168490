import React, { useState } from 'react'
import SiteHeader from '../components/siteHeader'
import Footer from '../components/footer/footer'
import ContactForm from '../components/contactForm'
import FeaturedProjects from '../components/page_blocks/featuredProjects'
import Seo from '../components/seo'

const ContactUsPage = () => {
	const [isMenuOpen, setMenuOpen] = useState(false)

	return (
		<div className="relative top-[82px] lg:top-[92px] xl:top-[114px]">
			<div className="min-h-screen max-h-screen min-w-screen max-w-screen">
				<SiteHeader
					isHomePage={false}
					isMenuOpen={isMenuOpen}
					setMenuOpen={setMenuOpen}
				/>

				<main className="mb-auto">
					<div className="article section">
						<div className="mx-auto">
							<h1 className="inline-block text-lg md:text-base">Contact Us</h1>
							<address className="hidden md:block not-italic align-top md:float-right mb-6 md:ml-6 text-lg pt-4">
								<span className="font-medium">Fast Forward LLC</span>
								<br />
								2 Margin Street #747
								<br />
								Salem, MA 01970
								<a
									className="block hover:underline pt-2"
									href="mailto:hello@fastforward.sh"
								>
									hello@fastforward.sh
								</a>
								<a
									href="tel:16179030361"
									className="hover:underline block pt-2"
								>
									617.903.0361
								</a>
								<div className="mt-4">
									<a href="https://www.linkedin.com/company/fast-forward-innovation">
										<img
											src="/Linkedin-black.svg"
											alt="Visit Fast Forward's LinkedIn page"
											className="hover:scale-110"
										/>
									</a>
								</div>
							</address>
							<div className="lg:w-4/5">
								<div className="max-w-[86ch]">
									<h2 className="h1">Let's Work Together</h2>
									<p className="text-base sm:text-3xl pb-8">
										Have a fun project you'd like to talk about? Want to join
										our team, or just say hi? Send us a quick message! We'd love
										to hear from you.
									</p>
								</div>
								<ContactForm />
							</div>
						</div>
					</div>
				</main>
				<FeaturedProjects />
				<div className="radial-gradient-background-small">
					<Footer />
				</div>
			</div>
		</div>
	)
}

export default ContactUsPage

export function Head() {
	return (
		<Seo
			title="Contact Us"
			description="Work with Fast Forward on your next project"
		/>
	)
}
