import React, { useState } from 'react'
import { navigate } from 'gatsby'

const ContactForm = () => {
	const initialState = {
		firstName: '',
		lastName: '',
		email: '',
		phone1: '',
		phone2: '',
		phone3: '',
		company: '',
		website: '',
		comments: '',
	}

	const initialErrors = {
		firstName: ['First name is required'],
		lastName: ['Last name is required'],
		email: ['Email is required', 'Email must include an @'],
		phone: [],
		company: [],
		website: [],
		comments: ["Please give us an idea of what you'd like to discuss!"],
	}

	const [formInfo, setFormInfo] = useState(initialState)
	const [errors, setErrors] = useState(initialErrors)
	const [displayErrors, setDisplayErrors] = useState(false)

	function handleInput(event) {
		event.preventDefault()
		let field = event.currentTarget.name
		let value = event.currentTarget.value
		setFormInfo(formInfo => ({ ...formInfo, [field]: value }))
		if (field === 'phone1') {
			validateForm('phone', value + formInfo['phone2'] + formInfo['phone3'])
		} else if (field === 'phone2') {
			validateForm('phone', formInfo['phone1'] + value + formInfo['phone3'])
		} else if (field === 'phone3') {
			validateForm('phone', formInfo['phone1'] + formInfo['phone2'] + value)
		} else {
			validateForm(field, value)
		}
	}

	function hasErrors(errors) {
		return Object.values(errors).some(field => field.length !== 0)
	}

	function validateForm(field, value) {
		switch (field) {
			case 'firstName':
				const firstNameErrors = []
				if (value.length === 0) {
					firstNameErrors.push('First name is required')
				}
				setErrors(errors => ({
					...errors,
					firstName: firstNameErrors,
				}))
				break
			case 'lastName':
				const lastNameErrors = []
				if (value.length === 0) {
					firstNameErrors.push('Last name is required')
				}
				setErrors(errors => ({
					...errors,
					lastName: lastNameErrors,
				}))
				break
			case 'email':
				const emailErrors = []
				if (value.length === 0) {
					emailErrors.push('Email is required')
				}
				if (value.indexOf('@') === -1) {
					emailErrors.push('Email should contain an @')
				}
				setErrors(() => ({
					...errors,
					email: emailErrors,
				}))
				break
			case 'phone':
				const phoneErrors = []
				if (value.length !== 0 && value.length !== 10) {
					phoneErrors.push(['Please enter exactly 10 digits'])
				}
				if (value.length !== 0 && !/^\d+$/.test(value)) {
					phoneErrors.push(['Phone number should include digits 1-9 only'])
				}
				setErrors(() => ({ ...errors, phone: phoneErrors }))
				break
			case 'comments':
				const commentErrors = []
				if (value.length === 0) {
					commentErrors.push(
						"Please give us an idea of what you'd like to discuss!",
					)
				}
				setErrors({
					...errors,
					comments: commentErrors,
				})
				break
			default:
				break
		}
	}

	async function handleSubmit(event) {
		event.preventDefault()
		if (hasErrors(errors)) {
			setDisplayErrors(true)
			return
		}

		try {
			const response = await fetch(
				'https://live-fast-forward-cms.pantheonsite.io/wp-json/ff-website-contacts/v1/submit',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(formInfo),
				},
			)
			if (response.ok) {
				navigate('/contact-submitted/?success=true')
			} else {
				throw new Error()
			}
		} catch (e) {
			navigate('/contact-submitted/?success=false')
		}
	}

	return (
		<form id="contact-form" className="text-base" onSubmit={handleSubmit}>
			<div className="sm:grid grid-cols-2 gap-x-8">
				<div
					className={
						displayErrors && errors['firstName'].length
							? 'contact-input input__required input__error'
							: 'contact-input input__required'
					}
				>
					<label htmlFor="firstName">First Name:</label>
					<input
						placeholder="Your first name"
						key="firstName"
						type="text"
						name="firstName"
						id="firstName"
						value={formInfo['firstName']}
						onChange={handleInput}
					></input>
					{displayErrors && errors['firstName'].length ? (
						<p className="text-xs text-ff_red">{errors['firstName'][0]}</p>
					) : (
						// hidden paragraphs when not showing errors just to prevent form input shift with DOM change
						<p className="text-xs opacity-0" aria-hidden="true">
							layout placeholder
						</p>
					)}
				</div>
				<div
					className={
						displayErrors && errors['lastName'].length
							? 'contact-input input__required input__error'
							: 'contact-input input__required'
					}
				>
					<label htmlFor="lastName">Last Name:</label>
					<input
						placeholder="Your last name"
						key="lastName"
						type="text"
						name="lastName"
						id="lastName"
						value={formInfo['lastName']}
						onChange={handleInput}
					></input>
					{displayErrors && errors['lastName'].length ? (
						<p className="text-xs text-ff_red">{errors['lastName'][0]}</p>
					) : (
						<p className="text-xs opacity-0" aria-hidden="true">
							layout placeholder
						</p>
					)}
				</div>
				<div
					className={
						displayErrors && errors['email'].length
							? 'contact-input input__required input__error'
							: 'contact-input input__required'
					}
				>
					<label htmlFor="email">Your Email:</label>
					<input
						placeholder="example@domain.com"
						key="email"
						type="text"
						name="email"
						id="email"
						value={formInfo['email']}
						onChange={handleInput}
					></input>
					{displayErrors && errors['email'].length ? (
						<p className="text-xs text-ff_red">{errors['email'][0]}</p>
					) : (
						<p className="text-xs opacity-0" aria-hidden="true">
							layout placeholder
						</p>
					)}
				</div>
				<div>
					<fieldset
						className={
							displayErrors && errors['phone'].length
								? 'contact-input relative input__error'
								: 'contact-input relative'
						}
					>
						<legend>Phone:</legend>
						<input
							aria-label="Area code"
							placeholder="XXX"
							key="phone1"
							type="text"
							name="phone1"
							id="phone1"
							value={formInfo['phone1']}
							maxLength="3"
							onChange={handleInput}
							className="w-[calc(25%-4px)] mr-2 text-center"
						></input>
						<input
							aria-label="First three digits of phone number"
							placeholder="XXX"
							key="phone2"
							type="text"
							name="phone2"
							id="phone2"
							maxLength="3"
							value={formInfo['phone2']}
							onChange={handleInput}
							className="w-[calc(25%-4px)] mr-2 text-center"
						></input>
						<input
							aria-label="Last four digits of phone number"
							placeholder="XXXX"
							key="phone3"
							type="text"
							name="phone3"
							id="phone3"
							maxLength="4"
							value={formInfo['phone3']}
							onChange={handleInput}
							className="w-[calc(50%-8px)] px-4 text-center"
						></input>
						{displayErrors && errors['phone'].length ? (
							<p className="text-xs text-ff_red">{errors['phone'][0]}</p>
						) : (
							<p className="text-xs opacity-0" aria-hidden="true">
								layout placeholder
							</p>
						)}
					</fieldset>
				</div>
				<div className="contact-input">
					<label htmlFor="company">Organization:</label>
					<input
						key="company"
						type="text"
						name="company"
						id="company"
						value={formInfo['company']}
						onChange={handleInput}
					></input>
					<p className="text-xs opacity-0" aria-hidden="true">
						layout placeholder
					</p>
				</div>
				<div
					className={
						displayErrors && errors['website'].length
							? 'contact-input input__error'
							: 'contact-input'
					}
				>
					<label htmlFor="website">Website:</label>
					<input
						key="website"
						type="text"
						name="website"
						id="website"
						value={formInfo['website']}
						onChange={handleInput}
					></input>
					{displayErrors && errors['website'].length ? (
						<p className="text-xs text-ff_red">{errors['website'][0]}</p>
					) : (
						<p className="text-xs opacity-0" aria-hidden="true">
							layout placeholder
						</p>
					)}
				</div>
			</div>
			<div
				className={
					displayErrors && errors['comments'].length
						? 'contact-input input__required input__error'
						: 'contact-input input__required'
				}
			>
				<label htmlFor="comments">
					Comments/Tell us about your project (Scope, Timeline, Budget):
				</label>
				<textarea
					name="comments"
					id="comments"
					value={formInfo.comments}
					onChange={handleInput}
					rows="5"
					className="w-full"
				></textarea>
				{displayErrors && errors['comments'].length ? (
					<p className="text-xs text-ff_red">{errors['comments'][0]}</p>
				) : (
					<p className="text-xs opacity-0" aria-hidden="true">
						layout placeholder
					</p>
				)}
			</div>
			<div>
				<button
					type="submit"
					className="btn linear-gradient-background-hover align-middle mr-8 text-white"
				>
					Contact Us
				</button>
				{hasErrors(errors) && displayErrors ? (
					<p className="inline-block text-sm text-ff_red bg-ff_red/10 py-5 px-6 mt-4">
						Please correct the highlighted fields above before submitting.
					</p>
				) : (
					<p className="inline-block text-md py-5 px-6 mt-4 sm:mt-[-40px] sm:float-right">
						<sup>*</sup>Required Fields
					</p>
				)}
			</div>
		</form>
	)
}
export default ContactForm
